import Login from "views/pages/login/Login.js";
import OwnerList from "./views/pages/user/OwnerList";
import OwnerProfile from "./views/pages/user/OwnerProfile";
import DMList from "./views/pages/device/DMList";
import StationManage from "./views/pages/device/StationManage";
import StationEdit from "./views/pages/device/StationEdit";
import Dashboard from "./views/pages/dashboards/Dashboard";
import Dashboard1 from "./views/pages/dashboards/Dashboard1";
import DSList from "./views/pages/device/DSList";
import DSManage from "./views/pages/device/DSManage";
import DMManage from "./views/pages/device/DMManage";
import DashboardCards from "./views/pages/dashboards/DashboardCards";
import MqttDashboard from "./views/pages/dashboards/MqttDashboard";

const routes = [
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    invisible: true,
    component: Login,
    layout: "/auth",
  },

  {
    collapse: true,
    name: "Dashboard",
    icon: "ni ni-single-02 text-default",
    state: "DashboardCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        miniName: "DB",
        component: Dashboard,
        layout: "/admin",
      },
      // {
      //   path: "/dashboard1",
      //   name: "Dashboard 1",
      //   miniName: "DB",
      //   component: Dashboard1,
      //   layout: "/admin",
      // },
      {
        path: "/dashboard-cards",
        name: "Card Dashboard",
        miniName: "CDB",
        component: DashboardCards,
        layout: "/admin",
      },
      {
        path: "/dashboard-mq",
        name: "MQTT Dashboard",
        miniName: "MDB",
        component: MqttDashboard,
        layout: "/admin",
      },
      {
        path: "/user-management",
        name: "User Management",
        miniName: "UM",
        component: OwnerProfile,
        layout: "/admin",
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Users",
    icon: "ni ni-single-02 text-default",
    state: "userCollapse",
    views: [
      {
        path: "/user-list",
        name: "User List",
        miniName: "U-L",
        component: OwnerList,
        layout: "/admin",
      },
      {
        path: "/user-management",
        name: "User Management",
        miniName: "UM",
        component: OwnerProfile,
        layout: "/admin",
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Devices",
    icon: "ni ni-shop text-default",
    state: "stationCollapse",
    views: [
      {
        path: "/device-master-list",
        name: "Device Master List",
        miniName: "DML",
        component: DMList,
        layout: "/admin",
      },
      {
        path: "/device-master-management",
        name: "Device Master Management",
        miniName: "DSM",
        component: DMManage,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/station-edit",
        name: "Station Edit",
        miniName: "SE",
        component: StationEdit,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/device-slave-list",
        name: "Device Slave List",
        miniName: "DSL",
        component: DSList,
        layout: "/admin",
      },

      {
        path: "/device-slave-management",
        name: "Device Slave Management",
        miniName: "DSM",
        component: DSManage,
        layout: "/admin",
        invisible: true,
      },
    ],
  },
];

export default routes;
