import React, { useState, useRef, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  ButtonGroup,
  CardDeck,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { makeUseAxios } from "axios-hooks";
import axios from "axios";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import paginationFactory from "react-bootstrap-table2-paginator";
import DashboardCard from "../../../components/Dashboard/dashboardCard";

const useMq = makeUseAxios({
  axios: axios.create({ baseURL: "http://amq.pumpguard.net/api/" }),
});
const useAxios = makeUseAxios({
  axios: axios.create({ baseURL: "https://api.pumpguard.net/api/" }),
});

function CardDashboard() {
  const [alert, setAlert] = useState(null);
  const [livedata, setlivedata] = useState([]);
  let live_devices = [];
  let all_devices_slave = [];
  const componentRef = useRef(null);
  const [cardData, setCardData] = useState([]);
  const [currSelection, setCurrSelection] = useState("All");

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      // 'authorization': 'Bearer '+sessionStorage.getItem('admin_accessToken')
    },
  };

  const [
    { data: getLiveD, loading: getLiveDLoading, error: getLiveDError },
    refreshLiveD,
  ] = useMq(
    {
      url: "session/show",
      method: "get",
      headers: config.header,
      auth: {
        username: "8n3R48u3mIyfrPe22MyydilMzgmkpANA",
      },
    },
    { manual: false }
  );
  const [{ data: getDS, loading: getDSLoading, error: getDSError }, refreshDS] =
    useAxios(
      {
        url: "ds/getAllDs",
        method: "get",
        headers: config.header,
      },
      { manual: false }
    );
  /*const [{ data: getDM, loading: getDMLoading, error: getDMError }, refreshDM] =
    useAxios(
      {
        url: "dm/getAllDm",
        method: "get",
        headers: config.header,
      },
      { manual: false }
    );*/

  let temp_name;
  let temp_contact;
  let temp_station;
  let temp_status;
  let temp_online;
  let temp_last_ping;
  let temp_address;
  let temp_city;
  let temp_zip;
  let type;
  let temp = 0;

  if (getDS && getLiveD && temp === 0) {
    temp = 1;
    live_devices = [];
    all_devices_slave = [];
    console.log(getDS);
    for (const val in Object.entries(getDS)) {
      if (getLiveD.table.find((live) => live.client_id === getDS[val].ds_mac)) {
        temp_name = getDS[val].ds_name;
        temp_contact = getDS[val].u_phone;
        temp_station = getDS[val].u_stationname;
        temp_address = getDS[val].u_address;
        temp_city = getDS[val].u_city;
        temp_zip = getDS[val].u_zip;
        temp_status = getDS[val].ds_state;
        temp_last_ping = getDS[val].ds_last_ping;
        temp_online = 0;

        all_devices_slave[val] = {
          index: val,
          name: temp_name,
          station: temp_station,
          contact: temp_contact,
          status: temp_status,
          last_ping: temp_last_ping,
          address: temp_address,
          city: temp_city,
          zip: temp_zip,
          online: 0,
        };
      } else {
        temp_name = getDS[val].ds_name;
        temp_contact = getDS[val].u_phone;
        temp_station = getDS[val].u_stationname;
        temp_status = getDS[val].ds_state;
        temp_address = getDS[val].u_address;
        temp_city = getDS[val].u_city;
        temp_zip = getDS[val].u_zip;
        temp_last_ping = getDS[val].ds_last_ping;
        temp_online = 1;

        all_devices_slave[val] = {
          index: val,
          name: temp_name,
          station: temp_station,
          contact: temp_contact,
          status: temp_status,
          last_ping: temp_last_ping,
          address: temp_address,
          city: temp_city,
          zip: temp_zip,
          online: 1,
        };
      }
      if (all_devices_slave[val] !== undefined) {
        temp_name = "";
        temp_contact = "";
        temp_station = "";
        temp_status = "";
        temp_last_ping = "";
        temp_online = "";
        temp_address = "";
        temp_city = "";
        temp_zip = "";
        type = "";
      }
    }

    for (const val in Object.entries(getLiveD.table)) {
      if (getDS.find((live) => live.ds_mac === getLiveD.table[val].client_id)) {
        temp_name = getDS.find(
          (live) => live.ds_mac === getLiveD.table[val].client_id
        ).ds_name;
        temp_station = getDS.find(
          (live) => live.ds_mac === getLiveD.table[val].client_id
        ).u_stationname;
        temp_contact = getDS.find(
          (live) => live.ds_mac === getLiveD.table[val].client_id
        ).u_phone;
        temp_status = getDS.find(
          (live) => live.ds_mac === getLiveD.table[val].client_id
        ).ds_state;
        temp_last_ping = getDS.find(
          (live) => live.ds_mac === getLiveD.table[val].client_id
        ).ds_last_ping;
        temp_address = getDS.find(
          (live) => live.ds_mac === getLiveD.table[val].client_id
        ).u_address;
        temp_city = getDS.find(
          (live) => live.ds_mac === getLiveD.table[val].client_id
        ).u_city;
        temp_zip = getDS.find(
          (live) => live.ds_mac === getLiveD.table[val].client_id
        ).u_city;
        type = "Slave";
        temp_online = 0;
      }
      if (temp_name && temp_station) {
        live_devices[val] = {
          index: val,
          mac: getLiveD.table[val].client_id,
          ip: getLiveD.table[val].peer_host,
          name: temp_name,
          station: temp_station,
          contact: temp_contact || "N/A",
          status: temp_status,
          last_ping: temp_last_ping,
          address: temp_address,
          city: temp_city,
          zip: temp_zip,
          type: type,
          online: temp_online,
        };
        temp_name = "";
        temp_contact = "";
        temp_station = "";
        temp_status = "";
        temp_last_ping = "";
        type = "";
        temp_address = "";
        temp_city = "";
        temp_zip = "";
        temp_online = "";
      }
    }
  }
  function refreshDevices() {
    refreshLiveD();
    refreshDS();
    temp = 0;
  }
  function refreshData() {
    if (currSelection === "All") {
      setCardData(all_devices_slave);
      console.log("setting all");
    } else if (currSelection === "Online") {
      setCardData(live_devices);
      console.log("setting omline");
    } else if (currSelection === "Offline") {
      setCardData(all_devices_slave);
      console.log("setting offline");
    }
  }

  // if (live_devices) {
  //   console.log(live_devices);
  // }
  useEffect(() => {
    if (currSelection === "All") {
      setCardData(all_devices_slave);
      console.log("setting all");
    } else if (currSelection === "Online") {
      setCardData(live_devices);
      console.log("setting omline");
    } else if (currSelection === "Offline") {
      setCardData(offline_devices);
      console.log("setting offline");
    }
    console.log("test");
  }, [temp]);

  function showOnlyOnline() {
    setCardData(live_devices);
    setCurrSelection("Online");
  }
  function showAllDevices() {
    setCardData(all_devices_slave);
    setCurrSelection("All");
  }
  let offline_devices = [];
  let offline_devices_count = 0;
  function showOnlyOffline() {
    for (const val in Object.entries(all_devices_slave)) {
      if (all_devices_slave[val].online === 1) {
        offline_devices[offline_devices_count] = all_devices_slave[val];
        offline_devices_count++;
      }
    }
    setCardData(offline_devices);
    setCurrSelection("Offline");
  }

  function showRefreshed() {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Refreshed"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="default"
        btnSize=""
      >
        Data Refreshed
      </ReactBSAlert>
    );
  }
  return (
    <>
      {alert}
      <SimpleHeader name="Dashboard" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center justify-content-between">
                  <Col xs="12" md="4">
                    <h3 className="mb-0">Device List</h3>
                    <small className={"text-muted"}>
                      Showing {currSelection}
                    </small>
                  </Col>

                  <Col xs="12" md="4">
                    <ButtonGroup>
                      <Button color={"default"} onClick={showOnlyOnline}>
                        Online
                      </Button>
                      <Button color={"default"} onClick={showOnlyOffline}>
                        Offline
                      </Button>
                      <Button color={"default"} onClick={showAllDevices}>
                        All
                      </Button>
                    </ButtonGroup>
                  </Col>
                  <Col xs="12" md="4" className="justify-content-end">
                    <Button
                      variant={"outline"}
                      color={"default"}
                      onClick={refreshDevices}
                    >
                      Refresh
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div
                  className={
                    "row row-cols-sm-2 rows-cols-xs-1 row-cols-md-4 row-cols-lg-6"
                  }
                >
                  {cardData &&
                    cardData.map((device) => (
                      <DashboardCard
                        index={device.index}
                        pump_name={device.name}
                        station_name={device.station}
                        online={device.online}
                        status={device.status}
                        contact={device.contact}
                        last_ping={device.last_ping}
                        address={device.address}
                        city={device.city}
                        zip={device.zip}
                      />
                    ))}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CardDashboard;
