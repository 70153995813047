// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

function CustomProfileHeader(props) {
  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center mt--3 mt-3 pt-5"
        style={{
          minHeight: "200px",
        }}
      >
        <span className="mask bg-gradient-default opacity-8" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="12">
              <h1 className="display-3 text-white">{props.heading}</h1>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CustomProfileHeader;
