
import React, {useState, useRef, useEffect} from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip, ButtonGroup,
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import {makeUseAxios} from "axios-hooks";
import axios from "axios";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import paginationFactory from "react-bootstrap-table2-paginator";



const pagination = paginationFactory({
  page: 1,
  sizePerPage:100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option selected value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
  ),
});

const { SearchBar } = Search;

const useMq = makeUseAxios({
  axios: axios.create({ baseURL: 'http://amq.pumpguard.net/api/' })
})
const useAxios = makeUseAxios({
  axios: axios.create({ baseURL: 'https://api.pumpguard.net/api/' })
})


function Dashboard1() {

  const [alert, setAlert] = useState(null);
  const [livedata,setlivedata] = useState([]);
  let live_devices=[]
  const componentRef = useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
        range,
        sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
        <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Good job!"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
    );
  };

  function StateStatusFormatter(cell, row) {
    if (row.status==='0') {
      return (
          <Badge className="badge-md" color="success" pill>
            <strong>Secured</strong>
          </Badge>
      );
    }

    if (row.status==='1') {
      return (
          <Badge className="badge-md" color="danger" pill>
            <strong>Triggered</strong>
          </Badge>
      );
    }

    if (row.status==='2') {
      return (
          <Badge className="badge-md" color="warning" pill>
            <strong>Maintenance</strong>
          </Badge>
      );
    }
  }

  function UserStatusFormatter(cell, row) {
    if (row.isActive) {
      return (
          <Badge className="badge-md" color="success" pill>
            <strong>Active</strong>
          </Badge>
      );
    }
    return (
        <Badge className="badge-md" color="warning" pill><strong >InActive</strong></Badge>
    );
  }

  const config =
      {
        header : {
          'Access-Control-Allow-Origin': '*',
          // 'authorization': 'Bearer '+sessionStorage.getItem('admin_accessToken')
        }
      }

  const [
    { data: getLiveD, loading: getLiveDLoading, error: getLiveDError },
    refreshLiveD
  ] = useMq(
      {
        url: 'session/show',
        method: 'get',
        headers: config.header,
        auth: {
          username:'8n3R48u3mIyfrPe22MyydilMzgmkpANA',
        }
      },
      { manual: false }
  )
  const [
    { data: getDS, loading: getDSLoading, error: getDSError },
    refreshDS
  ] = useAxios(
      {
        url: 'ds/getAllDs',
        method: 'get',
        headers: config.header
      },
      { manual: false }
  )
  const [
    { data: getDM, loading: getDMLoading, error: getDMError },
    refreshDM
  ] = useAxios(
      {
        url: 'dm/getAllDm',
        method: 'get',
        headers: config.header
      },
      { manual: false }
  )

  let temp_name;
  let temp_contact;
  let temp_station;
  let temp_status;
  let type;
  let temp=0;
  if(getDS && getLiveD && getDM && temp===0){
    temp=1;
    live_devices=[];
    for(const val in Object.entries(getLiveD.table)){
      if(getDS.find(live=>live.ds_mac === getLiveD.table[val].client_id)){
        temp_name= getDS.find(live=>live.ds_mac === getLiveD.table[val].client_id).ds_name;
        temp_station =getDS.find(live=>live.ds_mac === getLiveD.table[val].client_id).u_stationname;
        temp_contact =getDS.find(live=>live.ds_mac === getLiveD.table[val].client_id).u_phone;
        temp_status =getDS.find(live=>live.ds_mac === getLiveD.table[val].client_id).ds_state;
        type = "Slave"
      }
      if(getDM.find(live=>live.dm_mac === getLiveD.table[val].client_id)){
        temp_name= getDM.find(live=>live.dm_mac === getLiveD.table[val].client_id).dm_name;
        temp_station =getDM.find(live=>live.dm_mac === getLiveD.table[val].client_id).u_stationname;
        temp_contact =getDM.find(live=>live.dm_mac === getLiveD.table[val].client_id).u_phone;
        temp_status = ''
        type = "Master"
      }
      if(temp_name && temp_contact && temp_station && type){
        live_devices[val] = {
          "index": val,
          "mac": getLiveD.table[val].client_id,
          "ip":getLiveD.table[val].peer_host,
          "name":temp_name,
          "station": temp_station,
          "status": temp_status,
          "contact": temp_contact,
          "type": type,
        }
        temp_name="";
        temp_contact="";
        temp_station="";
        temp_status="";
        type="";
      }
      else{
        live_devices[val] = {
          "index": val,
          "mac": getLiveD.table[val].client_id,
          "ip":getLiveD.table[val].peer_host,
          "name":'Client',
          "station": 'App',
          "contact": '',
          "status": '',
          "type": "Client",
        }
      }
    }

  }

  if(live_devices){
    console.log(live_devices)
  }
  const column=[
    {
      dataField: 'index',
      text: 'Index',
      hidden: true,
    },
    {
      dataField: 'station',
      text: 'Station',
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'status',
      sort: true,
      formatter: StateStatusFormatter,
    },
    {
      dataField: 'contact',
      text: 'Contact',
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
    },
    {
      dataField: 'mac',
      text: 'MAC',
      sort: true,
    },
    {
      dataField: 'ip',
      text: 'IP',
      sort: true,
    },
  ]
  const dtcolumns = [
    {
      dataField: "client_id",
      text: "Device Mac Address",
      sort: true,
    }
    ,
    {
      dataField: "peer_host",
      text: "Device IP Address",
      sort: true,
    },
    {
      dataField: "is_online",
      text: "Is Online",
      sort: false,
    },
    /*
    {
      dataField: "btn",
          text: "Action",
        sort: false,
    },
    */
  ];
  const defaultSorted = [{
    dataField: 'client_id', // if dataField is not match to any column you defined, it will be ignored.
    order: 'desc' // desc or asc
  }];
  const Sorted = [{
    dataField: 'station', // if dataField is not match to any column you defined, it will be ignored.
    order: 'asc' // desc or asc
  }];
  function showRefreshed(){
    setAlert(
        <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Refreshed"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="default"
            btnSize=""
        >
          Data Refreshed
        </ReactBSAlert>
    );
  }
  return (
      <>
        {alert}
        <SimpleHeader name="Dashboard" parentName="Dashboards" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Online Device List</h3>
                    </Col>
                    <Col className="text-right" xs="4">

                    </Col>
                  </Row>
                </CardHeader>
                {live_devices && <ToolkitProvider
                    keyField="index"
                    columns= {column}
                    defaultSorted={ Sorted }
                    data={live_devices}
                    search
                >
                  {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={4}>
                              <ButtonGroup>
                                <Button
                                    className="buttons-copy buttons-html5"
                                    color="default"
                                    size="sm"
                                    id="copy-tooltip"
                                    onClick={() =>
                                        copyToClipboardAsTable(
                                            document.getElementById("react-bs-table")
                                        )
                                    }
                                >
                                  <span>Copy</span>
                                </Button>
                                <ReactToPrint
                                    trigger={() => (
                                        <Button
                                            color="default"
                                            size="sm"
                                            className="buttons-copy buttons-html5"
                                            id="print-tooltip"
                                        >
                                          Print
                                        </Button>
                                    )}
                                    content={() => componentRef.current}
                                />
                              </ButtonGroup>
                              <UncontrolledTooltip
                                  placement="top"
                                  target="print-tooltip"
                              >
                                This will open a print page with the visible rows of
                                the table.
                              </UncontrolledTooltip>
                              <UncontrolledTooltip
                                  placement="top"
                                  target="copy-tooltip"
                              >
                                This will copy to your clipboard the visible rows of
                                the table.
                              </UncontrolledTooltip>
                            </Col>

                            <Col xs={12} sm={6}>
                              <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                      className="form-control-sm"
                                      placeholder=""
                                      {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                            <Col xs={12} sm={2}>
                              <Button
                                  className="buttons-html5 mb-4"
                                  color="default"
                                  size="md"
                                  id="refresh"
                                  onClick={() =>
                                  {
                                    refreshDS();
                                    refreshDM();
                                    refreshLiveD();
                                    temp=0;
                                    showRefreshed();
                                  }
                                  }
                              >
                                <span>Refresh</span>
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                            ref={componentRef}
                            {...props.baseProps}
                            bootstrap4={true}
                            striped
                            hover
                            pagination={pagination}
                            bordered={true}
                            id="react-bs-table"
                        />
                      </div>
                  )}
                </ToolkitProvider>}

              </Card>
            </div>
          </Row>
        </Container>
      </>
  );
}

export default Dashboard1;
