import React, { useState } from "react";
import {
  Badge,
  Card,
  Collapse,
  Button,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Row,
  CardFooter,
  CardHeader,
} from "reactstrap";

function DashboardCard(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const {
    index,
    pump_name,
    station_name,
    online,
    status,
    contact,
    last_ping,
    address,
    city,
    zip,
  } = props;
  let isOnline;
  let device_status;

  if (online === 0) {
    isOnline = <Badge color="success">Online</Badge>;
  } else {
    isOnline = <Badge color="danger">Offline</Badge>;
  }

  if (status === "0") {
    device_status = (
      <Badge className={"badge-lg"} color="success">
        Secured
      </Badge>
    );
  } else if (status === "1") {
    device_status = (
      <Badge className={"badge-lg"} color="danger">
        Intrusion
      </Badge>
    );
  } else if (status === "2") {
    device_status = (
      <Badge className={"badge-lg"} color="warning">
        Maintenance
      </Badge>
    );
  } else {
    device_status = (
      <Badge className={"badge-lg"} color="primary">
        Unknown
      </Badge>
    );
  }

  return (
    <div className={"p-1"}>
      <Card className="border-dark shadow-lg ">
        <CardHeader>
          <Row>
            <Col sm={"10"}>
              <CardTitle>{pump_name ? pump_name : "Pump Name"}</CardTitle>
            </Col>
            <Col sm={"1"}>
              <Button
                className={"btn-icon btn-2"}
                color="primary"
                onClick={toggle}
                size={"sm"}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-bold-down" />
                </span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <h5>{station_name ? station_name : "Station Name"}</h5>
          <Row>
            <Col sm={12} className={"my-2"}>
              {device_status && device_status}
            </Col>
            <Col sm={12} className={"my-2"}>
              {isOnline && isOnline}
            </Col>

            <Collapse isOpen={isOpen}>
              <Row className={"m-2"}>
                <Col sm={12} className={""}>
                  <CardText>
                    <small className={"text-muted"}>Contact:</small>
                    <br />
                    <a href={"tel:" + contact} className={"text-dark"}>
                      {contact ? contact : "Not Added"}
                    </a>
                  </CardText>
                </Col>
                <Col>
                  <CardText>
                    <small className={"text-muted"}>Address:</small>
                    <br />
                    {address ? address : "Address"},
                    <br />
                    {city ? city : "city"},
                    <br />
                    {zip ? zip : "zip"}
                    <br />
                  </CardText>
                </Col>
              </Row>
            </Collapse>
          </Row>
        </CardBody>
        <CardFooter>
          <CardText>
            <small className="text-muted">
              {" "}
              Last Ping: <br />
              {last_ping ? last_ping : "Unknown"}
            </small>
          </CardText>
        </CardFooter>
      </Card>
    </div>
  );
}

export default DashboardCard;
