import React from "react";
import ReactDOM from "react-dom/client";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toggle/style.css";
// plugin styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
//import layout
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
// import sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://418c7e72fd644fd996499bbe42297768@o286790.ingest.sentry.io/4504610303311872",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

function getAuth(){
  return !!sessionStorage.getItem('admin_name');
  // return true;
}

const status=getAuth();

const root =ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
        {status &&
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            ||
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        }

      {/*<Route path="/" render={(props) => <AuthLayout {...props} />} />*/}
        {status &&

            <Redirect  from="*" to="/admin/dashboard" />||
            <Redirect from="*" to="/auth" />}
        {/*<Redirect from="*" to="/" />*/}
    </Switch>
  </BrowserRouter>
);