import React, { useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  Form,
  FormGroup,
  Input,
  Modal,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { station_tab, usertab } from "variables/general";
import useAxios from "axios-hooks";
import Select from "react-select";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option selected value="100">
              100
            </option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function DSList() {
  const [alert, setAlert] = React.useState(null);
  const [show_hidden, setShow_hidden] = useState(false);
  const show_hidden_password = "auranics9697";
  const [hidden_modal, sethidden_modal] = useState(false);
  const [hidden_password, sethidden_password] = useState("");

  function hidden_modal_toggle() {
    sethidden_modal(!hidden_modal);
  }
  function hidden_modal_confirm() {
    if (hidden_password === show_hidden_password) {
      setShow_hidden(true);
      sethidden_modal(false);
    } else {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Wrong Password!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Please try again!
        </ReactBSAlert>
      );
    }
  }
  const [NewDeviceModal, setNewDeviceModal] = useState(false);
  function closeModal() {
    if (NewDeviceModal) {
      setNewDeviceModal(false);
    } else {
      setNewDeviceModal(true);
    }
  }

  let newDsValue = {};
  const [NewDsObject, setNewDsObject] = useState();
  // useEffect(()=>{console.log(NewDsObject)},[NewDsObject])

  const handleChangeDs = (typeofObj, value) => {
    newDsValue[typeofObj] = value;
    setNewDsObject((NewDsObject) => ({
      ...NewDsObject,
      ...newDsValue,
    }));
    console.log(NewDsObject);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };

  function StateStatusFormatter(cell, row) {
    if (row.ds_state === "0") {
      return (
        <Badge className="badge-md" color="success" pill>
          <strong>Secured</strong>
        </Badge>
      );
    }

    if (row.ds_state === "1") {
      return (
        <Badge className="badge-md" color="danger" pill>
          <strong>Triggered</strong>
        </Badge>
      );
    }

    if (row.ds_state === "2") {
      return (
        <Badge className="badge-md" color="warning" pill>
          <strong>Maintenance</strong>
        </Badge>
      );
    }
  }

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      // 'authorization': 'Bearer '+sessionStorage.getItem('adsin_accessToken')
    },
  };

  const [{ data: putData, loading: putLoading, error: putError }, refreshData] =
    useAxios(
      {
        url: "https://api.pumpguard.net/api/ds/getAllDs",
        method: "get",
        headers: config.header,
      },
      { manual: false }
    );

  let pndata;
  let smsdata;

  const sendpushnotification = (username, name, station, last_ping) => {
    console.log(username, name, station, last_ping);
    pndata = {
      mesg:
        "Alert : Your Device " +
        name +
        " at station " +
        station +
        " is offline. Last ping was at " +
        last_ping,
      title: "Device Offline",
      un: username,
    };
    sendPNF({ data: pndata });
  };

  const sn = () => {
    sendPNF();
  };
  const sendsms = (username, name, station, last_ping) => {
    smsdata = {
      mesg:
        "Alert : Your Device " +
        name +
        " at station " +
        station +
        " is offline. Last ping was at " +
        last_ping,
      un: username,
    };
    sendSMSF({ data: smsdata });
  };
  const ssms = () => {
    sendSMSF();
  };
  const [
    { data: sendPN, loading: sendPNLoading, error: sendPNError },
    sendPNF,
  ] = useAxios(
    {
      url: "https://api.pumpguard.net/api/not/spa",
      method: "POST",
      headers: config.header,
    },
    { manual: true }
  );
  const [
    { data: sendSMS, loading: sendSMSLoading, error: sendSMSError },
    sendSMSF,
  ] = useAxios(
    {
      url: "https://api.pumpguard.net/api/not/ssm",
      method: "POST",
      headers: config.header,
      data: smsdata,
    },
    { manual: true }
  );
  if (putData) {
    console.log(putData);
    putData.map(
      (data, idx) =>
        (data.btn = (
          <a
            href={"./device-slave-management"}
            className="btn btn-success  btn-sm  d-print-none"
            onClick={(e) => sessionStorage.setItem("ds_manage_id", data._id)}
          >
            Manage
          </a>
        ))
    ); //to modify the button in the table
    putData.map(
      (data, idx) =>
        (data.pushbtn = (
          <button
            type={"button"}
            className="btn btn-outline-warning  btn-sm btn-icon  d-print-none"
            onClick={(event) => {
              sendpushnotification(
                data.u_username,
                data.ds_name,
                data.u_stationname,
                data.ds_last_ping
              );
            }}
          >
            <span className="btn-inner--text">Send PN</span>
            <span className="btn-inner--icon">
              <i className="ni ni-notification-70" />
            </span>
          </button>
        ))
    ); //to modify the button in the table

    putData.map(
      (data, idx) =>
        (data.smsbtn = (
          <button
            type={"button"}
            className="btn btn-outline-danger  btn-sm btn-icon  d-print-none"
            onClick={(event) => {
              sendsms(
                data.u_username,
                data.ds_name,
                data.u_stationname,
                data.ds_last_ping
              );
            }}
          >
            <span className="btn-inner--text">Send SMS</span>
            <span className="btn-inner--icon">
              <i className="ni ni-email-83" />
            </span>
          </button>
        ))
    ); //to modify the button in the table
  }

  const [{ data: getDM, loading: getDMLoading, error: getDMError }, refreshDM] =
    useAxios(
      {
        url: "https://api.pumpguard.net/api/dm/getAllDm",
        method: "get",
        headers: config.header,
      },
      { manual: false }
    );
  let DMList = [];
  let assignDMValue = {};
  const [assignDMObject, setassignDMObject] = useState();
  if (getDM) {
    console.log(getDM);
    getDM.map((data, idx) =>
      DMList.push({ value: data, label: data.u_stationname })
    );
  }

  const handleAssignDM = (typeofObj, value) => {
    assignDMValue[typeofObj] = value;
    setassignDMObject((assignStationObject) => ({
      ...assignStationObject,
      ...assignDMValue,
    }));
    console.log(assignDMObject);
  };

  const [
    { data: createDs, loading: createOLoading, error: createOError },
    createDsRequest,
  ] = useAxios(
    {
      url: "https://api.pumpguard.net/api/ds/register",
      method: "POST",
      headers: config.header,
      data: NewDsObject,
    },
    { manual: true }
  );

  function handleNewDsSubmit() {
    closeModal();
    createDsRequest();
    refreshData();
  }

  function showRefreshed() {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Refreshed"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="default"
        btnSize=""
      >
        Data Refreshed
      </ReactBSAlert>
    );
  }

  const defaultSorted = [
    {
      dataField: "u_stationname", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  return (
    <>
      {alert}
      <SimpleHeader name="Device Slave List" parentName="Device" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={false}
        size="xl"
        centered={true}
        scrollable={true}
        isOpen={NewDeviceModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Create Device Slave
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <h6 className="heading-small text-muted mb-4">
              Device Slave information
            </h6>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="u_id">
                      Station Of Device Master To Assign
                    </label>
                    <Select
                      options={DMList}
                      id={"U_id"}
                      onChange={(e) => {
                        handleChangeDs("u_id", e.value.u_id);
                        handleChangeDs("dm_id", e.value.dm_id);
                        handleChangeDs("dm_mac", e.value.dm_mac);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Device Slave Name
                    </label>
                    <Input
                      id="input-name"
                      type="text"
                      onChange={(e) => {
                        handleChangeDs("ds_name", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-mac">
                      Device Slave MAC
                    </label>
                    <Input
                      id="input-mac"
                      placeholder="Device Slave MAC"
                      type="text"
                      onChange={(e) => {
                        handleChangeDs("ds_mac", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="default" onClick={handleNewDsSubmit}>
            Add Device Slave
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Device Slave List</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="default" onClick={closeModal} size="md">
                      Add New Device Slave
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              {show_hidden === false && putData && (
                <ToolkitProvider
                  data={putData}
                  keyField="ds_mac"
                  defaultSorted={defaultSorted}
                  columns={[
                    {
                      dataField: "u_stationname",
                      text: "Station Name",
                      // hidden: true,
                      sort: true,
                    },
                    {
                      dataField: "ds_name",
                      text: "Device Name",
                      sort: true,
                    },
                    {
                      dataField: "ds_last_ping",
                      text: "Last Ping",
                      sort: true,
                    },
                    {
                      dataField: "ds_version",
                      text: "Version",
                      sort: true,
                    },
                    {
                      dataField: "ds_state",
                      text: "State",
                      sort: true,
                      formatter: StateStatusFormatter,
                    },
                    /*{
                                            dataField: "isActive",
                                            text: "Status",
                                            sort: true,
                                            formatter: StationStatusFormatter,
                                        },*/
                    {
                      dataField: "smsbtn",
                      text: "SMS",
                      sort: false,
                    },
                    {
                      dataField: "pushbtn",
                      text: "Notification",
                      sort: false,
                    },
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="p-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={4}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="default"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="default"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={4}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>

                          <Col xs={12} sm={2}>
                            <Button
                              aria-pressed={show_hidden}
                              autoComplete="off"
                              color="default"
                              className={"buttons-html5 mb-4"}
                              onClick={() => sethidden_modal(true)}
                            >
                              Show Hidden
                            </Button>
                          </Col>
                          <Col xs={12} sm={2}>
                            <Button
                              className="buttons-html5 mb-4"
                              color="default"
                              size="md"
                              id="refresh"
                              onClick={() => {
                                refreshData();
                                refreshDM();
                                showRefreshed();
                              }}
                            >
                              <span>Refresh</span>
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
              {show_hidden === true && putData && (
                <ToolkitProvider
                  data={putData}
                  keyField="ds_mac"
                  defaultSorted={defaultSorted}
                  columns={[
                    {
                      dataField: "u_stationname",
                      text: "Station Name",
                      // hidden: true,
                      sort: true,
                    },
                    {
                      dataField: "ds_name",
                      text: "Device Name",
                      sort: true,
                    },
                    {
                      dataField: "ds_mac",
                      text: "Unique ID",
                      sort: true,
                    },

                    {
                      dataField: "u_phone",
                      text: "Phone",
                      sort: true,
                    },

                    {
                      dataField: "ds_last_ping",
                      text: "Last Ping",
                      sort: true,
                    },
                    {
                      dataField: "ds_version",
                      text: "Version",
                      sort: true,
                    },
                    {
                      dataField: "ds_state",
                      text: "State",
                      sort: true,
                      formatter: StateStatusFormatter,
                    },
                    /*{
                                            dataField: "isActive",
                                            text: "Status",
                                            sort: true,
                                            formatter: StationStatusFormatter,
                                        },*/
                    {
                      dataField: "smsbtn",
                      text: "SMS",
                      sort: false,
                    },
                    {
                      dataField: "pushbtn",
                      text: "Notification",
                      sort: false,
                    },
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="p-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={4}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="default"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="default"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={4}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col xs={12} sm={2}>
                            <Button
                              aria-pressed={show_hidden}
                              autoComplete="off"
                              color="default"
                              className={"buttons-html5 mb-4 active"}
                              onClick={() => setShow_hidden(false)}
                            >
                              Lock Hidden
                            </Button>
                          </Col>
                          <Col xs={12} sm={2}>
                            <Button
                              className="buttons-html5 mb-4"
                              color="default"
                              size="md"
                              id="refresh"
                              onClick={() => {
                                refreshData();
                                refreshDM();
                                showRefreshed();
                              }}
                            >
                              <span>Refresh</span>
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={hidden_modal}
          toggle={() => hidden_modal_toggle()}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <div className="text-muted text-center mt-2 mb-3">
                  Enter Password To Show Hidden Fields
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                <Form
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    hidden_modal_confirm();
                  }}
                >
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onChange={(e) => {
                          sethidden_password(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-2"
                      color="default"
                      type="submit"
                      onClick={() => {
                        hidden_modal_confirm();
                      }}
                    >
                      Unlock
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </Container>
    </>
  );
}

export default DSList;
