import React, { useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  Form,
  FormGroup,
  Input,
  Modal,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { station_tab, usertab } from "variables/general";
import { makeUseAxios } from "axios-hooks";
import Select from "react-select";
import { parse } from "list.js";
import axios from "axios";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option selected value="100">
              100
            </option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
const useAxios = makeUseAxios({
  axios: axios.create({ baseURL: "https://api.pumpguard.net/api" }),
});

function DMList() {
  const [alert, setAlert] = React.useState(null);
  const [show_hidden, setShow_hidden] = useState(false);

  const show_hidden_password = "auranics9697";
  const [hidden_modal, sethidden_modal] = useState(false);
  const [hidden_password, sethidden_password] = useState("");

  function hidden_modal_toggle() {
    sethidden_modal(!hidden_modal);
  }
  function hidden_modal_confirm() {
    if (hidden_password === show_hidden_password) {
      setShow_hidden(true);
      sethidden_modal(false);
    } else {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Wrong Password!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Please try again!
        </ReactBSAlert>
      );
    }
  }
  const [NewDeviceModal, setNewDeviceModal] = useState(false);
  function closeModal() {
    if (NewDeviceModal) {
      setNewDeviceModal(false);
    } else {
      setNewDeviceModal(true);
    }
  }

  let newDMValue = {};
  const [NewDMObject, setNewDMObject] = useState();

  // useEffect(()=>{console.log(NewDMObject)},[NewDMObject])

  const handleChangeDM = (typeofObj, value) => {
    newDMValue[typeofObj] = value;
    setNewDMObject((NewDMObject) => ({
      ...NewDMObject,
      ...newDMValue,
    }));
    console.log(NewDMObject);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };

  function StationStatusFormatter(cell, row) {
    if (row.isActive) {
      return (
        <Badge className="badge-md" color="success" pill>
          <strong>Active</strong>
        </Badge>
      );
    }
    return (
      <Badge className="badge-md" color="warning" pill>
        <strong>InActive</strong>
      </Badge>
    );
  }

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      // 'authorization': 'Bearer '+sessionStorage.getItem('admin_accessToken')
    },
  };

  const [{ data: putData, loading: putLoading, error: putError }, refreshData] =
    useAxios(
      {
        url: "dm/getAllDm",
        method: "get",
        headers: config.header,
      },
      { manual: false }
    );

  if (putData) {
    console.log(putData);
    putData.map(
      (data, idx) =>
        (data.btn = (
          <a
            href={"device-master-management"}
            className="btn btn-success  btn-sm  d-print-none"
            onClick={(e) => {
              sessionStorage.setItem("dm_manage_id", data.dm_id);
              sessionStorage.setItem("dm_manage_uid", data.u_id);
            }}
          >
            Manage
          </a>
        ))
    ); //to modify the button in the table
  }

  const [
    { data: getUsers, loading: getULoading, error: getUError },
    refreshUsers,
  ] = useAxios(
    {
      url: "user/getAllUser",
      method: "get",
      headers: config.header,
    },
    { manual: false }
  );
  let UserList = [];
  let assignUserValue = {};
  const [assignUserObject, setassignUserObject] = useState();
  if (getUsers) {
    getUsers.map((data, idx) =>
      UserList.push({ value: data, label: data.u_stationname })
    );
  }

  const handleAssignUser = (typeofObj, value) => {
    assignUserValue[typeofObj] = value;
    setassignUserObject((assignStationObject) => ({
      ...assignStationObject,
      ...assignUserValue,
    }));
    console.log(assignUserObject);
  };

  const [
    { data: createDM, loading: createOLoading, error: createOError },
    createDMRequest,
  ] = useAxios(
    {
      url: "dm/register",
      method: "POST",
      headers: config.header,
      data: NewDMObject,
    },
    { manual: true }
  );

  function handleNewDMSubmit() {
    closeModal();
    createDMRequest();
    refreshData();
  }

  const defaultSorted = [
    {
      dataField: "u_stationname", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  return (
    <>
      {alert}
      <SimpleHeader name="Device Master List" parentName="Device" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={false}
        size="xl"
        centered={true}
        scrollable={true}
        isOpen={NewDeviceModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Create Device Master
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <h6 className="heading-small text-muted mb-4">
              Device Master information
            </h6>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="u_id">
                      Station To Assign
                    </label>
                    <Select
                      options={UserList}
                      id={"U_id"}
                      onChange={(e) => {
                        handleChangeDM("u_id", e.value.u_id);
                        handleChangeDM("u_username", e.value.u_username);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Device Master Name
                    </label>
                    <Input
                      id="input-name"
                      type="text"
                      onChange={(e) => {
                        handleChangeDM("dm_name", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-mac">
                      Device Master MAC
                    </label>
                    <Input
                      id="input-mac"
                      placeholder="Device Master MAC"
                      type="text"
                      onChange={(e) => {
                        handleChangeDM("dm_mac", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="default" onClick={handleNewDMSubmit}>
            Add Device Master
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Device Master List</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="default" onClick={closeModal} size="md">
                      Add New Device Master
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              {show_hidden === true && putData && (
                <ToolkitProvider
                  data={putData}
                  keyField="dm_mac"
                  defaultSorted={defaultSorted}
                  columns={[
                    {
                      dataField: "u_stationname",
                      text: "Station Name",
                      // hidden: true,
                      sort: true,
                    },
                    {
                      dataField: "dm_name",
                      text: "Device Name",
                      sort: true,
                    },
                    {
                      dataField: "dm_mac",
                      text: "Unique ID",
                      sort: true,
                    },
                    {
                      dataField: "u_phone",
                      text: "Phone",
                      sort: true,
                    },
                    {
                      dataField: "dm_last_ping",
                      text: "Last Ping",
                      sort: true,
                    },
                    {
                      dataField: "dm_version",
                      text: "Version",
                      sort: true,
                    },
                    /* {
                                        dataField: "isActive",
                                        text: "Status",
                                        sort: true,
                                        formatter: StationStatusFormatter,
                                    },*/
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="p-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="default"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="default"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={4}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col xs={12} sm={2}>
                            <Button
                              aria-pressed={show_hidden}
                              autoComplete="off"
                              color="default"
                              className={"buttons-html5 mb-4 active"}
                              onClick={() => setShow_hidden(false)}
                            >
                              Lock Hidden
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
              {show_hidden === false && putData && (
                <ToolkitProvider
                  data={putData}
                  keyField="dm_mac"
                  defaultSorted={defaultSorted}
                  columns={[
                    {
                      dataField: "u_stationname",
                      text: "Station Name",
                      // hidden: true,
                      sort: true,
                    },
                    {
                      dataField: "dm_name",
                      text: "Device Name",
                      sort: true,
                    },
                    {
                      dataField: "dm_last_ping",
                      text: "Last Ping",
                      sort: true,
                    },
                    {
                      dataField: "dm_version",
                      text: "Version",
                      sort: true,
                    },
                    /* {
                                             dataField: "isActive",
                                             text: "Status",
                                             sort: true,
                                             formatter: StationStatusFormatter,
                                         },*/
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="p-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="default"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="default"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={4}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col xs={12} sm={2}>
                            <Button
                              aria-pressed={show_hidden}
                              autoComplete="off"
                              color="default"
                              className={"buttons-html5 mb-4"}
                              onClick={() => sethidden_modal(true)}
                            >
                              Show Hidden
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={hidden_modal}
          toggle={() => hidden_modal_toggle()}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <div className="text-muted text-center mt-2 mb-3">
                  Enter Password To Show Hidden Fields
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                <Form
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    hidden_modal_confirm();
                  }}
                >
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onChange={(e) => {
                          sethidden_password(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-2"
                      color="default"
                      type="submit"
                      onClick={() => {
                        hidden_modal_confirm();
                      }}
                    >
                      Unlock
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </Container>
    </>
  );
}

export default DMList;