import React, { useEffect, useRef, useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Form,
  Badge,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { usertab } from "variables/general";

// api components
import axios from "axios";
import { makeUseAxios } from "axios-hooks";
import { func } from "prop-types";

const useAxios = makeUseAxios({
  axios: axios.create({ baseURL: "https://api.pumpguard.net/api/" }),
});

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option selected value="100">
              100
            </option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function OwnerList() {
  const [alert, setAlert] = useState(null);
  const componentRef = useRef(null);
  const [show_hidden, setShow_hidden] = useState(false);

  const show_hidden_password = "auranics9697";
  const [hidden_modal, sethidden_modal] = useState(false);
  const [hidden_password, sethidden_password] = useState("");

  const [NewUserModal, setNewUserModal] = useState(false);
  function closeModal() {
    if (NewUserModal) {
      setNewUserModal(false);
    } else {
      setNewUserModal(true);
    }
  }

  function hidden_modal_toggle() {
    sethidden_modal(!hidden_modal);
  }
  function hidden_modal_confirm() {
    if (hidden_password === show_hidden_password) {
      setShow_hidden(true);
      sethidden_modal(false);
    } else {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Wrong Password!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Please try again!
        </ReactBSAlert>
      );
    }
  }
  let newOwnerValue = {};
  const [NewOwnerObject, setNewOwnerObject] = useState();

  // useEffect(()=>{console.log(NewOwnerObject)},[NewOwnerObject])

  const handleChangeOwner = (typeofObj, value) => {
    newOwnerValue[typeofObj] = value;
    setNewOwnerObject((NewOwnerObject) => ({
      ...NewOwnerObject,
      ...newOwnerValue,
    }));
    console.log(NewOwnerObject);
  };

  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };

  function UserStatusFormatter(cell, row) {
    if (row.isActive) {
      return (
        <Badge className="badge-md" color="success" pill>
          <strong>Active</strong>
        </Badge>
      );
    }
    return (
      <Badge className="badge-md" color="warning" pill>
        <strong>InActive</strong>
      </Badge>
    );
  }

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      // 'authorization': 'Bearer '+sessionStorage.getItem('admin_accessToken')
    },
  };

  const [
    { data: getOwners, loading: putLoading, error: putError },
    refreshOwners,
  ] = useAxios(
    {
      url: "user/getAllUser",
      method: "get",
      headers: config.header,
    },
    { manual: false }
  );

  if (getOwners) {
    getOwners.map(
      (data, idx) =>
        (data.btn = (
          <a
            href={"./user-management"}
            className="btn btn-success btn-sm d-print-none"
            aria-disabled={true}
            onClick={() => sessionStorage.setItem("user_manage_id", data.u_id)}
          >
            Manage
          </a>
        ))
    );
    //to modify the button in the table
  }

  const [
    { data: createOwner, loading: createOLoading, error: createOError },
    createOwnerRequest,
  ] = useAxios(
    {
      url: "user/register_user",
      method: "POST",
      headers: config.header,
      data: NewOwnerObject,
    },
    { manual: true }
  );

  function handleNewOwnerSubmit() {
    closeModal();
    createOwnerRequest();
    refreshOwners();
  }

  return (
    <>
      {alert}
      <SimpleHeader name="User List" parentName="User" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={false}
        size="xl"
        centered={true}
        scrollable={true}
        isOpen={NewUserModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Create User
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <h6 className="heading-small text-muted mb-4">User information</h6>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      Email address
                    </label>
                    <Input
                      id="input-email"
                      placeholder="jesse@example.com"
                      type="email"
                      onChange={(e) => {
                        handleChangeOwner("u_email", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Station Name
                    </label>
                    <Input
                      id="input-name"
                      placeholder="Station name"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_stationname", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-uname">
                      Username
                    </label>
                    <Input
                      id="input-uname"
                      placeholder="Username"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_username", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-pass">
                      Password
                    </label>
                    <Input
                      id="input-pass"
                      placeholder="Password"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_password", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-phone">
                      Phone
                    </label>
                    <Input
                      id="input-phone"
                      placeholder="Phone"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_phone", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-fname">
                      First Name
                    </label>
                    <Input
                      id="input-fname"
                      placeholder="First Name"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_fname", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-lname">
                      Last Name
                    </label>
                    <Input
                      id="input-lname"
                      placeholder="Last Name"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_lname", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <hr className="my-4" />

            <h6 className="heading-small text-muted mb-4">
              Contact information
            </h6>
            <div className="pl-lg-4">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Address
                    </label>
                    <Input
                      id="input-address"
                      placeholder="Address"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_address", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-city">
                      City
                    </label>
                    <Input
                      id="input-city"
                      placeholder="City"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_city", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-state">
                      State
                    </label>
                    <Input
                      id="input-state"
                      placeholder="State"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_state", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-zip">
                      Zip Code
                    </label>
                    <Input
                      id="input-zip"
                      placeholder="Zip Code"
                      type="text"
                      onChange={(e) => {
                        handleChangeOwner("u_zip", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="default" onClick={handleNewOwnerSubmit}>
            Add User
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">User List</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="default" onClick={closeModal} size="md">
                      Add New User
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {show_hidden === false && getOwners && (
                <ToolkitProvider
                  data={getOwners}
                  keyField="u_id"
                  columns={[
                    {
                      dataField: "u_stationname",
                      text: "Station Name",
                      sort: true,
                    },
                    {
                      dataField: "u_fname",
                      text: "Name",
                      sort: true,
                    },
                    {
                      dataField: "u_id",
                      hidden: true,
                      sort: false,
                    },
                    /*{
                                        dataField: "isActive",
                                        text: "Status",
                                        sort: true,
                                        formatter: UserStatusFormatter
                                    },*/
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="default"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="default"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={4}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col xs={12} sm={2}>
                            <Button
                              aria-pressed={show_hidden}
                              autoComplete="off"
                              color="default"
                              className={"buttons-html5 mb-4"}
                              onClick={() => sethidden_modal(true)}
                            >
                              Show Hidden
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        striped
                        hover
                        pagination={pagination}
                        bordered={true}
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}

              {show_hidden === true && getOwners && (
                <ToolkitProvider
                  data={getOwners}
                  keyField="u_id"
                  columns={[
                    {
                      dataField: "u_stationname",
                      text: "Station Name",
                      sort: true,
                    },
                    {
                      dataField: "u_fname",
                      text: "Name",
                      sort: true,
                    },
                    {
                      dataField: "u_id",
                      hidden: true,
                      sort: false,
                    },
                    /*{
                                        dataField: "isActive",
                                        text: "Status",
                                        sort: true,
                                        formatter: UserStatusFormatter
                                    },*/
                    {
                      dataField: "u_email",
                      text: "Email",
                      sort: true,
                    },
                    {
                      dataField: "u_phone",
                      text: "Phone",
                      sort: true,
                    },
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="default"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="default"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={4}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col xs={12} sm={2}>
                            <Button
                              aria-pressed={show_hidden}
                              autoComplete="off"
                              color="default"
                              className={"buttons-html5 mb-4 active"}
                              onClick={() => setShow_hidden(false)}
                            >
                              Lock Hidden
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        striped
                        hover
                        pagination={pagination}
                        bordered={true}
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={hidden_modal}
          toggle={() => hidden_modal_toggle()}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <div className="text-muted text-center mt-2 mb-3">
                  Enter Password To Show Hidden Fields
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                <Form
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    hidden_modal_confirm();
                  }}
                >
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onChange={(e) => {
                          sethidden_password(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-2"
                      color="default"
                      type="submit"
                      onClick={() => {
                        hidden_modal_confirm();
                      }}
                    >
                      Unlock
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </Container>
    </>
  );
}

export default OwnerList;
