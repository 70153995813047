// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
  Badge,
  ButtonGroup,
  InputGroup,
  Label,
  Table,
} from "reactstrap";
// core components
import CustomProfileHeader from "components/Headers/CustomProfileHeader.js";
import { makeUseAxios } from "axios-hooks";
import axios from "axios";
import React, { useRef, useState } from "react";
import Toggle from "react-toggle";
import Avatar from "react-avatar";
import Select from "react-select";

const useAxios = makeUseAxios({
  axios: axios.create({ baseURL: "https://api.pumpguard.net/api/" }),
});

function DMManage() {
  const dm_id = sessionStorage.getItem("dm_manage_id");
  const [DM_status, setDM_status] = useState();
  const [DM_firmware, setDM_firmware] = useState();

  let dsListRef = useRef();

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("admin_accessToken"),
    },
  };

  const [{ data: getDM, loading: getSLoading, error: getSError }, refreshDM] =
    useAxios(
      {
        url: "dm/getDmByDmid",
        method: "POST",
        headers: config.header,
        data: {
          dm_id: sessionStorage.getItem("dm_manage_id"),
        },
      },
      { manual: false }
    );

  const [{ data: getDS, loading: getDSLoading, error: getDSError }, refreshDS] =
    useAxios(
      {
        url: "ds/getDSByUID",
        method: "POST",
        headers: config.header,
        data: {
          u_id: sessionStorage.getItem("dm_manage_uid"),
        },
      },
      { manual: false }
    );
  const [
    { data: getUser, loading: getUserLoading, error: getUserError },
    refreshUser,
  ] = useAxios(
    {
      url: "user/getUserByUid/",
      method: "POST",
      headers: config.header,
      data: {
        u_id: sessionStorage.getItem("dm_manage_uid"),
      },
    },
    { manual: false }
  );
  const [
    { data: getFirmware, loading: getFirmwareLoading, error: getFirmwareError },
    refreshFirmware,
  ] = useAxios(
    {
      url: "dota/get_list",
      method: "GET",
      headers: config.header,
      data: {},
    },
    { manual: false }
  );

  let FirmwareList = [];
  if (getFirmware) {
    console.log(getFirmware);
    getFirmware.map((data, idx) =>
      FirmwareList.push({ value: data, label: data.name })
    );
  }
  let DMState;

  //for updateing  new DM State
  function handleDMStateChange(e) {
    setDM_status(e.target.checked);
    console.log(DM_status);
  }

  const [
    { data: updateDMStatus, loading: updateSSLoading, error: updateSSError },
    updateDMStatusRequest,
  ] = useAxios(
    {
      url: "dm/udm",
      method: "POST",
      headers: config.header,
      data: {
        dm_id: dm_id,
        dm_ota_status: DM_status,
        dm_ota_url: DM_firmware,
      },
    },
    { manual: true }
  );
  function handleDMStatusUpdate() {
    updateDMStatusRequest();
    refreshDM();
    alert("DM Status Updated Successfully");
  }
  return (
    <>
      <CustomProfileHeader
        className="mt--3 pt-5"
        heading={"Device Master Manage"}
      />
      <Container className="mt--6 pt-3" fluid>
        {getDM && (
          <Row>
            <Col className="order-lg-1" lg="12">
              <Card>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <Col lg="12" className="h1 mt-2">
                        {getDM && getDM.dm_name}
                      </Col>
                    </div>

                    <div>
                      <Button
                        className="float-right "
                        color="default"
                        disabled={true}
                        href="./dm-edit"
                        onClick={(e) => e.preventDefault()}
                        size="lg"
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </CardHeader>

                <CardBody className="pt-0">
                  {getUser && (
                    <Row className="p-4">
                      {/*<Col lg="12" className="h1 mt-2">
                      Device Name : {getDM.dm_name}
                    </Col>*/}
                      <Col lg={"6"} className={"row"}>
                        <Col lg="12" className="h3 mt-2 ">
                          <span className="h4 font-weight-light p-1">
                            Station Name :{" "}
                          </span>
                          {getUser.u_stationname}
                        </Col>

                        <Col lg="12" className="h3 mt-2">
                          <span className="h4 font-weight-light p-1">
                            {" "}
                            Phone
                          </span>{" "}
                          :{" "}
                          <a href={"tel:" + getUser.u_phone}>
                            {getUser.u_phone}
                          </a>
                        </Col>
                        <Col lg="12" className="h3 mt-2">
                          <span className="h4 font-weight-light p-1">
                            {" "}
                            Email
                          </span>{" "}
                          :{" "}
                          <a href={"mailto:" + getUser.u_email}>
                            {getUser.u_email}
                          </a>
                        </Col>
                      </Col>
                      <Col lg={"6"} className={"row"}>
                        <Col lg={"12"}>
                          <span className="h4 font-weight-light p-1">
                            {" "}
                            Harware Version
                          </span>{" "}
                          : <span>{getDM.dm_hardware_version}</span>
                        </Col>
                        <Col lg={"12"}>
                          <span className="h4 font-weight-light p-1">
                            {" "}
                            Firmware Version
                          </span>{" "}
                          : <span>{getDM.dm_version}</span>
                        </Col>
                        <Col lg={"12"}>
                          <span className="h4 font-weight-light p-1">
                            {" "}
                            OTA Status
                          </span>{" "}
                          : <span>{getDM.dm_ota_status.toString()}</span>
                        </Col>
                        <Col lg={"12"}>
                          <span className="h4 font-weight-light p-1">
                            {" "}
                            OTA Firmware
                          </span>{" "}
                          : <span>{getDM.dm_ota_url}</span>
                        </Col>
                        <Col lg={"12"}>
                          <span className="h4 font-weight-light p-1">
                            {" "}
                            OTA STATE:
                          </span>{" "}
                          : <span>{getDM.dm_ota_state}</span>
                        </Col>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col className="order-lg-2" lg={"5"} md={"5"}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Master OTA Status</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="danger"
                        id="new-DM"
                        onClick={handleDMStatusUpdate}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                        </span>
                        <span className="btn-inner--text">Update</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <FormGroup>
                    <Label
                      for={"DM_status"}
                      className="h4 font-weight-500 pr-2"
                    >
                      OTA Status :
                    </Label>
                    {getDM && (
                      <Toggle
                        aria-labelledby="DM Status"
                        aria-label="DM Status"
                        name="DM_status"
                        id="DM_status"
                        defaultChecked={getDM.dm_ota_status}
                        onChange={handleDMStateChange}
                      />
                    )}{" "}
                  </FormGroup>

                  <FormGroup>
                    <div className="h4 font-weight-500">OTA Firmware :</div>

                    <Select
                      options={FirmwareList}
                      id={"firmware_master"}
                      onChange={(e) => {
                        console.log(e.value);
                        setDM_firmware(e.value.name);
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>

            <Col className="order-lg-3" lg={"7"} md={"7"}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Device Slave List</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="warning"
                        id="new-DM"
                        onClick={handleDMStatusUpdate}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                        </span>
                        <span className="btn-inner--text">
                          Bulk Status Change
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div ref={dsListRef}>
                    <Table className="align-items-center" responsive striped>
                      <thead className="thead-light">
                        <tr>
                          <th className="sort" data-sort="name" scope="col">
                            Name
                          </th>
                          <th className="sort" data-sort="status" scope="col">
                            Status
                          </th>
                          <th className="sort" data-sort="firmware" scope="col">
                            Firmware
                          </th>
                          <th />
                        </tr>
                      </thead>

                      <tbody className="list">
                        {getDS &&
                          getDS.map((data, idx) => (
                            <tr key={idx}>
                              <td className="name">
                                <b>{data.ds_name}</b>
                              </td>
                              <td className="status">
                                {data.ds_ota_status ? (
                                  <b>
                                    <Badge
                                      className="badge-md"
                                      color="success"
                                      pill
                                    >
                                      <strong>Active</strong>
                                    </Badge>
                                  </b>
                                ) : (
                                  <b>
                                    <Badge
                                      className="badge-md"
                                      color="danger"
                                      pill
                                    >
                                      <strong>Inactive</strong>
                                    </Badge>
                                  </b>
                                )}
                              </td>
                              <td className="firmware">
                                <span>{data.ds_ota_url}</span>
                              </td>
                              <td className="table-actions">
                                <a
                                  className="table-action table-action-delete"
                                  href="./device-slave-management"
                                  onClick={() =>
                                    sessionStorage.setItem(
                                      "ds_manage_id",
                                      data.ds_id
                                    )
                                  }
                                >
                                  <i className="fas fa-user-check text-success" />
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default DMManage;
