
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Progress,
    Container,
    Row,
    Col, Badge, ButtonGroup,
} from "reactstrap";
// core components
import CustomProfileHeader from "components/Headers/CustomProfileHeader.js";
import {makeUseAxios} from "axios-hooks";
import axios from "axios";
import React, {useState} from "react";
import Toggle from "react-toggle";
import Avatar from "react-avatar";

const useAxios = makeUseAxios({
    axios: axios.create({ baseURL: 'https://api.pumpguard.net/a_api/' })
})

function StationManage() {

    const station_id = sessionStorage.getItem('station_manage_id');
    const [Station_status,setStation_status] = useState();

    const config =
        {
            header : {
                'Access-Control-Allow-Origin': '*',
                'authorization': 'Bearer '+sessionStorage.getItem('admin_accessToken')
            }
        }

    const [
        { data: getStation, loading: getSLoading, error: getSError },
        refreshStation
    ] = useAxios(
        {
            url: 'getStationById',
            method: 'POST',
            headers: config.header,
            data: {
                stationId: sessionStorage.getItem('station_manage_id')
            }
        },
        { manual: false }
    )

    let StationState;

    //for updateing  new Station State
    function handleStationStateChange(e) {
        setStation_status(e.target.checked)
        console.log( Station_status )
    }

    const [
        { data: updateStationStatus, loading: updateSSLoading, error: updateSSError },updateStationStatusRequest] = useAxios(
        {
            url: 'updateStation/'+station_id,
            method: 'PUT',
            headers: config.header,
            data: {
                isActive:Station_status
            }
        },
        { manual: true }
    )
    function handleStationStatusUpdate() {
        updateStationStatusRequest();
        refreshStation();
        alert("Station Status Updated Successfully");
    }
    return (
        <>
            <CustomProfileHeader heading={'Station Manage'} />
            <Container className="mt--6 pt-3" fluid>
                {getStation &&
                    <Row>
                        <Col className="order-lg-1" lg='12'>

                            <Card className="card-profile">
                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                        <div className="card-profile-image">
                                             <Avatar round={true} color={'#f15a29'}  name= {getStation.name} size={'100px'} style={{marginTop:'-50px'}}/>

                                        </div>
                                    </Col>
                                </Row>

                                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    <div className="d-flex justify-content-between">
                                        {/*<Button
                                        className="mr-4"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="lg"
                                    >
                                        Connect
                                    </Button>*/}
                                        <div>
                                        {getStation.isActive && <Badge color="success" className="badge-lg" pill>Active</Badge> ||
                                            <Badge color="danger" className="badge-lg"  pill>InActive</Badge>}
                                        </div>



                                        <div>
                                        <Button
                                            className="float-right "
                                            color="default"
                                            href="./station-edit"
                                            // onClick={(e) => e.preventDefault()}
                                            size="lg"
                                        >
                                            Edit
                                        </Button>
                                        </div>
                                    </div>
                                </CardHeader>

                                <CardBody className="pt-0">
                                    <Row>
                                        <div className="col-12">
                                            <div className="card-profile-stats d-flex justify-content-center">
                                                <div>
                                                    <span className="heading"><a href={'./owner-management'}
                                                                                 onClick={() => sessionStorage.setItem('owner_manage_id', getStation.owner)}>{getStation.ownerName}</a></span>
                                                    <span className="description">Owner</span>
                                                </div>
                                                <div>
                                                    <span className="heading"><a href={'./manager-management'}
                                                                                 onClick={() => sessionStorage.setItem('manager_manage_id', getStation.manager)}>{getStation.managerName}</a></span>
                                                    <span className="description">Manager</span>
                                                </div>
                                                <div>
                                                    <span className="heading"><a href={'./op-management'}
                                                                                 onClick={() => sessionStorage.setItem('op_manage_id', getStation.operator)}>{getStation.operatorName}</a></span>
                                                    <span className="description">Operator</span>
                                                </div>
                                                <div>
                                                    <span className="heading">
                                                        <a href={'./jobber-management'} onClick={() => sessionStorage.setItem('jobber_manage_id', getStation.jobber)}>
                                                            {getStation.jobberName}
                                                        </a>
                                                    </span>
                                                    <span className="description">Jobber</span>
                                                </div>
                                                {/* <div>
                                                <span className="heading">Company Name</span>
                                                <span className="description">Company</span>
                                            </div>*/}
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className='p-4'>
                                        <Col lg='12' className="h1 mt-2">
                                            {getStation.stationName} ,
                                            <span className="font-weight-light p-1"> {getStation.stationAddress},</span>
                                            <span
                                                className="font-weight-light p-1"> {getStation.city} , {getStation.state}</span><br/>
                                            <span
                                                className="font-weight-light p-1"> {getStation.country} , {getStation.zip}</span>
                                        </Col>


                                        <Col lg='6' className="h3 mt-2 ">

                                            <span className="h4 font-weight-light p-1">Company Name : </span>
                                            {getStation.companyName}
                                        </Col>
                                        <Col lg='6' className="h3 mt-2">
                                            <span className="h4 font-weight-light p-1"> Station Code : </span>
                                            {getStation.stationCode}
                                        </Col>
                                        <Col lg='6' className="h3 mt-2">
                                            <span className="h4 font-weight-light p-1"> Phone</span> : <a
                                            href={'tel:' + getStation.phone}>{getStation.phone}</a>
                                        </Col>
                                        <Col lg='6' className="h3 mt-2">
                                            <span className="h4 font-weight-light p-1">  Email</span> : <a
                                            href={'mailto:' + getStation.email}>{getStation.email}</a>
                                        </Col>

                                        <Col lg='6' className="h3 mt-2 ">

                                            <span className="h4 font-weight-light p-1">License Number : </span>
                                            {getStation.licenseNumber}
                                        </Col>
                                        <Col lg='6' className="h3 mt-2 ">

                                            <span className="h4 font-weight-light p-1"> Proprietary Details : </span>
                                            {getStation.proprietaryDetails}
                                        </Col>
                                        <Col lg='6' className="h3 mt-2 ">

                                            <span
                                                className="h4 font-weight-light p-1">Fuel Tank And other Information : </span>
                                            {getStation.fuelTankAndOtherSiteInformation}
                                        </Col>

                                        <Col lg='6' className="h3 mt-2 ">

                                            <span className="h4 font-weight-light p-1">Site Of Company : </span>
                                            {getStation.siteOfTheCompany}
                                        </Col>

                                        <Col lg='6' className="h3 mt-2 ">

                                            <span className="h4 font-weight-light p-1">Contact Person Details : </span>
                                            {getStation.contactPersonDetails}
                                        </Col>

                                    </Row>
                                </CardBody>

                            </Card>
                        </Col>


                        <Col className="order-lg-2" lg={'12'}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs="6">
                                            <h3 className="mb-0">Status</h3>
                                        </Col>
                                        <Col className="text-right" xs="6">
                                            <Button
                                                className="btn-round btn-icon"
                                                color="danger"

                                                id="new-station"
                                                onClick={handleStationStatusUpdate}
                                                size="sm"
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-user-edit"/>
                                                </span>
                                                <span className="btn-inner--text">Update</span>
                                            </Button>

                                        </Col>
                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <Row>
                                        <Col>
                                            <div className="h4 font-weight-500">
                                                Station Status :
                                            </div>
                                        </Col>
                                        <Col>
                                            <Toggle
                                                aria-labelledby='Station Status'
                                                aria-label='Station Status'
                                                name='station_status'
                                                id='station_status'
                                                defaultChecked={getStation.isActive}
                                                onChange={handleStationStateChange}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="order-lg-2" lg={'12'}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs="6">
                                            <h3 className="mb-0">Change Associated Users </h3>
                                        </Col>

                                    </Row>
                                </CardHeader>

                                <CardBody className={'d-flex justify-content-start'}>

                                        <Button
                                            className=" "
                                            color="danger"

                                            onClick={(e) => e.preventDefault()}
                                            size="lg"
                                        >
                                            Change Manager
                                        </Button>
                                        <Button
                                            className=" "
                                            color="danger"

                                            onClick={(e) => e.preventDefault()}
                                            size="lg"
                                        >
                                            Change Operator
                                        </Button>
                                        <Button
                                            className=" "
                                            color="danger"

                                            onClick={(e) => e.preventDefault()}
                                            size="lg"
                                        >
                                            Change Jobber
                                        </Button>

                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                }
            </Container>
        </>
    );
}

export default StationManage;
