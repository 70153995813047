
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Progress,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import CustomProfileHeader from "components/Headers/CustomProfileHeader.js";
import Select2 from "react-select2-wrapper";
import {makeUseAxios} from "axios-hooks";
import axios from "axios";
import React, {useEffect, useState} from "react";
import Avatar from "react-avatar";

const useAxios = makeUseAxios({
    axios: axios.create({ baseURL: 'https://api.pumpguard.net/a_api/' })
})

function StationEdit() {

    const station_id = sessionStorage.getItem('station_manage_id');
    const config =
        {
            header : {
                'Access-Control-Allow-Origin': '*',
                'authorization': 'Bearer '+sessionStorage.getItem('admin_accessToken')
            }
        }

    const [
        { data: getStation, loading: getSLoading, error: getSError },
        refreshStation
    ] = useAxios(
        {
            url: 'getStationById',
            method: 'POST',
            headers: config.header,
            data: {
                stationId: station_id
            }
        },
        { manual: false }
    )

    let editStationValue={};
    const[EditStationObject,setEditStationObject] = useState();

    useEffect(()=>{console.log(EditStationObject)},[EditStationObject])

    const handleEditStation = (typeofObj,value) => {

        editStationValue[typeofObj] = value;
        setEditStationObject(EditStationObject => ({
            ...EditStationObject,
            ...editStationValue
        }));
        console.log(EditStationObject)
    }
    const [
        { data: updateStation, loading: updateSLoading, error: updateSError },updateStationRequest] = useAxios(
        {
            url: 'updateStation/'+station_id,
            method: 'PUT',
            headers: config.header,
            data:EditStationObject
        },
        { manual: true }
    )
    function handleChangeStation() {
        updateStationRequest();
            refreshStation();
            alert("Station Updated Successfully");
            window.location.href = "/admin/station-management";

    }
    return (
        <>
            <CustomProfileHeader heading={'Station Edit'} />
            <Container className="mt--6" fluid>
                <Row>
                    <Col className="order-lg-1" lg='12' >
                        {getStation &&
                        <Card className="card-profile">
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <Avatar round={true} color={'#f15a29'}  name= {getStation.name} size={'100px'} style={{marginTop:'-50px'}}/>

                                    </div>
                                </Col>
                            </Row>
                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">

                                    <Button
                                        className="float-right "
                                        color="success"
                                        href="#pablo"
                                        onClick={handleChangeStation}
                                        size="lg"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className="float-right "
                                        color="danger"
                                        href="./station-management"
                                        size="lg"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">

                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Station Master Information
                                    </h6>
                                    <div className="pl-lg-4">
                                        {/*<Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="station-owner"
                                                    >
                                                        Station Owner
                                                    </label>
                                                    <Select2
                                                        className="form-control"
                                                        defaultValue="1"
                                                        options={{
                                                            placeholder: "Select"
                                                        }}
                                                        id={'station-owner'}
                                                        data={[
                                                            { id: "1", text: "Alerts" },
                                                            { id: "2", text: "Badges" },
                                                            { id: "3", text: "Buttons" },
                                                            { id: "4", text: "Cards" },
                                                            { id: "5", text: "Forms" },
                                                            { id: "6", text: "Modals" }
                                                        ]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="station-manager"
                                                    >
                                                        Station Manager
                                                    </label>
                                                    <Select2
                                                        className="form-control"
                                                        defaultValue="1"
                                                        options={{
                                                            placeholder: "Select"
                                                        }}
                                                        id={'station-manager'}
                                                        data={[
                                                            { id: "1", text: "Alerts" },
                                                            { id: "2", text: "Badges" },
                                                            { id: "3", text: "Buttons" },
                                                            { id: "4", text: "Cards" },
                                                            { id: "5", text: "Forms" },
                                                            { id: "6", text: "Modals" }
                                                        ]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="station-operator"
                                                    >
                                                        Station Operator
                                                    </label>
                                                    <Select2
                                                        className="form-control"
                                                        defaultValue="1"
                                                        options={{
                                                            placeholder: "Select"
                                                        }}
                                                        id={'station-operator'}
                                                        data={[
                                                            { id: "1", text: "Alerts" },
                                                            { id: "2", text: "Badges" },
                                                            { id: "3", text: "Buttons" },
                                                            { id: "4", text: "Cards" },
                                                            { id: "5", text: "Forms" },
                                                            { id: "6", text: "Modals" }
                                                        ]}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>*/}
                                    </div>
                                    <h6 className="heading-small text-muted mb-4">
                                        Station Main Information
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>

                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Email address
                                                    </label>
                                                    <Input
                                                        id="input-email"
                                                        placeholder="jesse@example.com"
                                                        type="email"
                                                        defaultValue={getStation.email}

                                                        onChange={(e)=>handleEditStation('email',e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-name"
                                                    >
                                                        Station Name
                                                    </label>
                                                    <Input
                                                        onChange={(e)=>handleEditStation('stationName',e.target.value)}
                                                        id="input-name"
                                                        placeholder="Station name"
                                                        type="text"

                                                        defaultValue={getStation.stationName}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-code"
                                                    >
                                                        Station Code
                                                    </label>
                                                    <Input
                                                        id="input-code"
                                                        placeholder="Station Code"
                                                        type="text"

                                                        defaultValue={getStation.stationCode}
                                                        onChange={(e)=>handleEditStation('stationCode',e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-phone"
                                                    >
                                                        Phone
                                                    </label>
                                                    <Input
                                                        id="input-phone"
                                                        placeholder="Phone"
                                                        type="text"

                                                        defaultValue={getStation.phone}
                                                        onChange={(e)=>handleEditStation('phone',e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </div>
                                    <hr className="my-4" />

                                    <h6 className="heading-small text-muted mb-4">
                                        Station Contact information
                                    </h6>
                                    <div className="pl-lg-4">

                                        <Row>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Address
                                                    </label>
                                                    <Input
                                                        id="input-address"
                                                        placeholder="Address"
                                                        type="text"
                                                        defaultValue={getStation.stationAddress}
                                                        onChange={(e) => {handleEditStation('stationAddress',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        City
                                                    </label>
                                                    <Input
                                                        id="input-city"
                                                        placeholder="City"
                                                        type="text"
                                                        defaultValue={getStation.city}
                                                        onChange={(e) => {handleEditStation('city',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-state"
                                                    >
                                                        State
                                                    </label>
                                                    <Input
                                                        id="input-state"
                                                        placeholder="State"
                                                        type="text"
                                                        defaultValue={getStation.state}
                                                        onChange={(e) => {handleEditStation('state',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Country
                                                    </label>
                                                    <Input
                                                        id="input-country"
                                                        placeholder="Country"
                                                        type="text"
                                                        defaultValue={getStation.country}
                                                        onChange={(e) => {handleEditStation('country',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-zip"
                                                    >
                                                        Zip Code
                                                    </label>
                                                    <Input
                                                        id="input-zip"
                                                        placeholder="Zip Code"
                                                        type="text"
                                                        defaultValue={getStation.zip}
                                                        onChange={(e) => {handleEditStation('zip',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </div>
                                    <hr className="my-4" />

                                    <h6 className="heading-small text-muted mb-4">
                                        Station Proprietary Information
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-License-Number"
                                                    >
                                                        License Number
                                                    </label>
                                                    <Input
                                                        defaultValue={getStation.licenseNumber}
                                                        id="input-License-Number"
                                                        placeholder="License Number"
                                                        type="text"
                                                        onChange={(e) => {handleEditStation('licenseNumber',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-Proprietary-number"
                                                    >
                                                        Proprietary Details
                                                    </label>
                                                    <Input
                                                        id="input-Proprietary-number"
                                                        placeholder="Proprietary Details"
                                                        type="text"
                                                        defaultValue={getStation.proprietaryDetails}
                                                        onChange={(e) => {handleEditStation('proprietaryDetails',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-Fuel-Tank"
                                                    >
                                                        Fuel Tank and Other Information
                                                    </label>
                                                    <Input
                                                        id="input-Fuel-Tank"
                                                        placeholder="Fuel Tank and Other Information"
                                                        type="text"
                                                        defaultValue={getStation.fuelTankAndOtherSiteInformation}
                                                        onChange={(e) => {handleEditStation('fuelTankAndOtherSiteInformation',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-Site-Company"
                                                    >
                                                        Site Of Company
                                                    </label>
                                                    <Input
                                                        id="input-Site-Company"
                                                        placeholder="Site Of Company"
                                                        type="text"
                                                        defaultValue={getStation.siteOfTheCompany}
                                                        onChange={(e) => {handleEditStation('siteOfTheCompany',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-Contact-Details"
                                                    >
                                                        Contact Person Details
                                                    </label>
                                                    <Input
                                                        id="input-Contact-Details"
                                                        placeholder="Contact Person Details"
                                                        type="text"
                                                        defaultValue={getStation.contactPersonDetails}
                                                        onChange={(e) => {handleEditStation('contactPersonDetails',e.target.value)}}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </div>
                                </Form>

                            </CardBody>
                        </Card>
                        }


                    </Col>

                </Row>
            </Container>
        </>
    );
}

export default StationEdit;
