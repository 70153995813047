import React, { useState } from "react";
import {
  Badge,
  Card,
  Collapse,
  Button,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Row,
  CardFooter,
  CardHeader,
} from "reactstrap";

function MqDashboardCard(props) {
  const [isOpen, setIsOpen] = useState(false);

  let isOnline;
  let device_status;
  let status;
  const toggle = () => setIsOpen(!isOpen);
  const { data } = props;
  status = data.status;
  if (status === "0") {
    device_status = (
      <Badge className={"badge-lg"} color="success">
        Secured
      </Badge>
    );
  } else if (status === "1") {
    device_status = (
      <Badge className={"badge-lg"} color="danger">
        Intrusion
      </Badge>
    );
  } else if (status === "2") {
    device_status = (
      <Badge className={"badge-lg"} color="warning">
        Maintenance
      </Badge>
    );
  } else {
    device_status = (
      <Badge className={"badge-lg"} color="primary">
        Not On Mqtt
      </Badge>
    );
  }
  return (
    <div className={"p-1"}>
      <Card className="border-dark shadow-lg ">
        <CardHeader>
          <Row>
            <Col sm={"10"}>
              <CardTitle>{data && data.name}</CardTitle>
              <CardSubtitle>{data && data.station}</CardSubtitle>
            </Col>
            <Col sm={"1"}>
              <Button
                className={"btn-icon btn-2"}
                color="primary"
                onClick={toggle}
                size={"sm"}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-bold-down" />
                </span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <h5>{data ? "Station Name" : "test"}</h5>
          <Row>
            <Col sm={12} className={"my-2"}>
              {device_status && device_status}
            </Col>
            {/*<Col sm={12} className={"my-2"}>*/}
            {/*  {data.isOnline && data.isOnline}*/}
            {/*</Col>*/}

            <Collapse isOpen={isOpen}>
              <Row className={"m-2"}>
                <Col sm={12} className={""}>
                  <CardText>
                    <small className={"text-muted"}>Contact:</small>
                    <br />
                    <a href={"tel:" + data.contact} className={"text-dark"}>
                      {data.contact ? data.contact : "Contact Number"}
                    </a>
                  </CardText>
                </Col>
                <Col>
                  <CardText>
                    <small className={"text-muted"}>Address:</small>
                    <br />
                    {data.address ? data.address : "Address"},
                    <br />
                    {data.city ? data.city : "city"},
                    <br />
                    {data.zip ? data.zip : "zip"}
                    <br />
                  </CardText>
                </Col>
              </Row>
            </Collapse>
          </Row>
        </CardBody>

        <CardFooter>
          <CardText>
            <small className="text-muted">
              {" "}
              Last Ping: <br />
              {data.last_ping ? data.last_ping : "Unknown"}
            </small>
          </CardText>
        </CardFooter>
      </Card>
    </div>
  );
}

export default MqDashboardCard;
