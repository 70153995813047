// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Badge,
  Modal,
} from "reactstrap";
// core components
import CustomProfileHeader from "components/Headers/CustomProfileHeader.js";
import React, { useEffect, useRef, useState } from "react";
import List from "list.js";
import axios from "axios";
import { makeUseAxios } from "axios-hooks";
import Select from "react-select";
import Toggle from "react-toggle";
import { bool } from "prop-types";
import Avatar from "react-avatar";

const useAxios = makeUseAxios({
  axios: axios.create({ baseURL: "https://api.pumpguard.net/api" }),
});

function OwnerProfile() {
  let managerListRef = useRef();
  let opListRef = useRef();
  let dsListRef = useRef();
  const [NewManagerModal, setNewManagerModal] = useState(false);
  const [NewStationModal, setNewStationModal] = useState(false);
  const [ManagerModalStationSelect, setManagerModalStationSelect] =
    useState(null);
  const [Owner_status, setOwner_status] = useState();
  const user_id = sessionStorage.getItem("user_manage_id");
  const isActive = true;
  function closeManagerModal() {
    if (NewManagerModal) {
      setNewManagerModal(false);
    } else {
      setNewManagerModal(true);
    }
  }
  function closeStationModal() {
    if (NewStationModal) {
      setNewStationModal(false);
    } else {
      setNewStationModal(true);
    }
  }

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      // 'authorization': 'Bearer '+sessionStorage.getItem('admin_accessToken')
    },
  };

  const [
    { data: getOwner, loading: getOLoading, error: getOError },
    refreshOwner,
  ] = useAxios(
    {
      url: "user/getUserByUid",
      method: "POST",
      headers: config.header,
      data: {
        u_id: user_id,
      },
    },
    { manual: false }
  );
  const [
    { data: getSlaveD, loading: getSDLoading, error: getSDError },
    refreshSlaveD,
  ] = useAxios(
    {
      url: "ds/getDSByUID",
      method: "POST",
      headers: config.header,
      data: {
        u_id: user_id,
      },
    },
    { manual: false }
  );

  //for editing owner
  let OwnerState;

  //for creating new manager
  function handleOwnerStateChange(e) {
    setOwner_status(e.target.checked);
    console.log(Owner_status);
  }

  let editOwnerValue = { u_id: user_id };
  const [EditOwnerObject, setEditOwnerObject] = useState();

  useEffect(() => {
    console.log(EditOwnerObject);
  }, [EditOwnerObject]);

  const handleEditOwner = (typeofObj, value) => {
    editOwnerValue[typeofObj] = value;
    setEditOwnerObject((EditOwnerObject) => ({
      ...EditOwnerObject,
      ...editOwnerValue,
    }));
    console.log(EditOwnerObject);
  };
  const [
    { data: updateOwner, loading: updateOLoading, error: updateOError },
    updateOwnerRequest,
  ] = useAxios(
    {
      url: "user/uu/",
      method: "POST",
      headers: config.header,
      data: EditOwnerObject,
    },
    { manual: true }
  );
  function handleOwnerUpdate() {
    updateOwnerRequest();
    refreshOwner();
    alert("Owner Updated Successfully");
  }
  return (
    <>
      <CustomProfileHeader heading={"Owner Profile"} />

      <Container className="mt--6" fluid>
        <Row>
          {getOwner && (
            <Col className="order-lg-1 mt-2 pt-5" lg="4">
              <Row>
                <Col lg="12">
                  <Card className="card-profile">
                    <Row className="justify-content-center">
                      <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image">
                          <Avatar
                            round={true}
                            color={"#f15a29"}
                            name={getOwner.u_fname}
                            size={"100px"}
                            style={{ marginTop: "-50px" }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                      {/*<div className="d-flex justify-content-between">
                                    <Button
                                        className="mr-4"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="sm"
                                    >
                                        Connect
                                    </Button>
                                    <Button
                                        className="float-right"
                                        color="default"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="sm"
                                    >
                                        Message
                                    </Button>
                                </div>*/}
                    </CardHeader>
                    <CardBody className="pt-0">
                      <Row>
                        <div className="col">
                          <div className="card-profile-stats d-flex justify-content-center">
                            <div>
                              <span className="heading">
                                {getSlaveD && getSlaveD.length}
                              </span>
                              <span className="description">Slave Devices</span>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <div className="text-center">
                        <div>
                          <span className={"h2 mr-1"}>
                            {getOwner.u_fname + " " + getOwner.u_lname}
                          </span>{" "}
                          {(getOwner.isActive && (
                            <Badge color="success">Active</Badge>
                          )) || <Badge color="danger">InActive</Badge>}
                        </div>
                        <div className="h4 mt-4">Station</div>
                        <div className={"h1"}> {getOwner.u_stationname} </div>
                        <div className="h4 font-weight-500 mt-4">
                          {getOwner.u_address} ,<br /> {getOwner.u_city},{" "}
                          {getOwner.u_state} ,<br /> {getOwner.country}
                        </div>
                        <div className="h4 mt-4">
                          Zip Code : {getOwner.u_zip}
                        </div>
                        <div className="h4 mt-4">
                          Phone:{" "}
                          <a href={"tel:" + getOwner.u_phone}>
                            {" "}
                            {getOwner.u_phone}
                          </a>
                        </div>
                        <div className="h4 mt-4">
                          Email:{" "}
                          <a href={"mailto:" + getOwner.u_email}>
                            {" "}
                            {getOwner.u_email}
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          )}
          <Col className="order-lg-2" lg="8">
            <Row>
              {getSlaveD && (
                <Col lg={"12"}>
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col xs="6">
                          <h3 className="mb-0">Device Slave</h3>
                        </Col>
                        <Col className="text-right" xs="6"></Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div ref={dsListRef}>
                        <Table
                          className="align-items-center"
                          responsive
                          striped
                        >
                          <thead className="thead-light">
                            <tr>
                              <th className="sort" data-sort="name" scope="col">
                                Name
                              </th>
                              <th
                                className="sort"
                                data-sort="status"
                                scope="col"
                              >
                                Status
                              </th>
                              <th />
                            </tr>
                          </thead>

                          <tbody className="list">
                            {getSlaveD.map((data, idx) => (
                              <tr key={idx}>
                                <td className="name">
                                  <b>{data.ds_name}</b>
                                </td>
                                <td className="status">
                                  <b>
                                    {(isActive && (
                                      <Badge
                                        className="badge-md"
                                        color="success"
                                        pill
                                      >
                                        <strong>Active</strong>
                                      </Badge>
                                    )) || (
                                      <Badge
                                        className="badge-md"
                                        color="warning"
                                        pill
                                      >
                                        <strong>InActive</strong>
                                      </Badge>
                                    )}
                                  </b>
                                </td>
                                <td className="table-actions">
                                  <a
                                    className="table-action table-action-delete"
                                    href="./device-slave-management"
                                    onClick={() =>
                                      sessionStorage.setItem(
                                        "ds_manage_id",
                                        data._id
                                      )
                                    }
                                  >
                                    <i className="fas fa-user-check text-success" />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
            <Row>
              <Col lg={"12"}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6">
                        <h3 className="mb-0">Status</h3>
                      </Col>
                      <Col className="text-right" xs="6">
                        <Button
                          className="btn-round btn-icon"
                          color="danger"
                          id="new-station"
                          // onClick={handleOwnerStatusUpdate}
                          size="sm"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-user-edit" />
                          </span>
                          <span className="btn-inner--text">Update</span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      <Col>
                        <div className="h4 font-weight-500">
                          Station Status :
                        </div>
                      </Col>
                      <Col>
                        <Toggle
                          aria-labelledby="Owner Status"
                          aria-label="Owner Status"
                          name="owner_status"
                          id="owner_status"
                          // defaultChecked={getOwner.isActive}
                          // onChange={handleOwnerStateChange}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col className="order-lg-3" lg="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit profile</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="default"
                      onClick={handleOwnerUpdate}
                      size="md"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {getOwner && (
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              id="input-email"
                              placeholder="jesse@example.com"
                              type="email"
                              defaultValue={getOwner.u_email}
                              onChange={(e) => {
                                handleEditOwner("u_email", e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              First Name
                            </label>
                            <Input
                              defaultValue={getOwner.u_fname}
                              onChange={(e) => {
                                handleEditOwner("u_fname", e.target.value);
                              }}
                              id="input-name"
                              placeholder="First name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              Last Name
                            </label>
                            <Input
                              defaultValue={getOwner.u_lname}
                              onChange={(e) => {
                                handleEditOwner("u_lname", e.target.value);
                              }}
                              id="input-name"
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              Station Name
                            </label>
                            <Input
                              defaultValue={getOwner.u_stationname}
                              onChange={(e) => {
                                handleEditOwner(
                                  "u_stationname",
                                  e.target.value
                                );
                              }}
                              id="input-name"
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Phone
                            </label>
                            <Input
                              defaultValue={getOwner.u_phone}
                              onChange={(e) => {
                                handleEditOwner("u_phone", e.target.value);
                              }}
                              id="input-phone"
                              placeholder="Phone"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              id="input-address"
                              placeholder="Address"
                              type="text"
                              defaultValue={getOwner.u_address}
                              onChange={(e) => {
                                handleEditOwner("u_address", e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              id="input-city"
                              placeholder="City"
                              type="text"
                              defaultValue={getOwner.u_city}
                              onChange={(e) => {
                                handleEditOwner("u_city", e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-state"
                            >
                              State
                            </label>
                            <Input
                              id="input-state"
                              placeholder="State"
                              type="text"
                              defaultValue={getOwner.u_state}
                              onChange={(e) => {
                                handleEditOwner("u_state", e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-zip"
                            >
                              Zip Code
                            </label>
                            <Input
                              id="input-zip"
                              placeholder="Zip Code"
                              type="text"
                              defaultValue={getOwner.u_zip}
                              onChange={(e) => {
                                handleEditOwner("u_zip", e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OwnerProfile;